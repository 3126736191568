<template>
  <BaseModal
    name="coupon-new"
    size="lg"
    title="Adicione o Cupom de Desconto"
    @hidden="reset"
    @shown="openModal"
  >
    <b-row>
      <b-col cols="12">
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-form novalidate v-show="!loading">
          <b-row>
            <b-col cols="12" md="5">
              <b-form-group label="Código do cupom" label-for="name">
                <b-form-input
                  style="text-transform: uppercase"
                  id="name"
                  name="name"
                  v-model="cupom.name"
                  type="text"
                  placeholder="Ex: VOOMP50"
                  v-validate="{ required: true, regex: /^[A-Z\-_0-9]{5,15}$/i }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  Por favor, insira o código do cupom.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Tipo" label-for="type">
                <b-form-select
                  name="type"
                  v-model="cupom.type"
                  :options="types"
                  v-validate="'required'"
                ></b-form-select>
                <b-form-invalid-feedback :state="!errors.has('type')">
                  Por favor, selecione o tipo.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group
                v-if="cupom.type == 'PERCENTAGE'"
                label="Valor em %"
                label-for="amount"
              >
                <b-form-input
                  id="amount"
                  name="amount"
                  v-model="cupom.amount"
                  type="number"
                  min="1"
                  max="100"
                  v-validate="'required|decimal|min_value:1|max_value:100'"
                ></b-form-input>

                <b-form-invalid-feedback :state="!errors.has('amount')">
                  Por favor, insira o valor corretamente.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                v-if="cupom.type != 'PERCENTAGE'"
                label="Valor em R$"
                label-for="amount"
              >
                <money
                  id="amount"
                  name="amount"
                  v-model="cupom.amount"
                  v-bind="money"
                ></money>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="7"
              v-if="
                !$store.getters.setPanel ||
                  ($store.getters.setPanel && product_read)
              "
            >
              <b-form-group
                id="only_products"
                label=""
                label-for="only_products"
              >
                <b-form-checkbox
                  name="only_products"
                  v-model="cupom.only_products"
                  size="lg"
                  switch
                >
                  <p class="info-checkbox">
                    Apenas para alguns produtos
                    <span class="opcional">(opcional)</span>
                    <span class="descricao-checkbox"
                      >Exclusivo para alguns produtos
                    </span>
                  </p>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="cupom.only_products" class="mb-2">
              <b-form-group label="Quais produtos aceitarão esse cupom ?">
                <multiselect
                  v-model="cupom.products"
                  id="products_cupom"
                  name="products_cupom"
                  label="name"
                  track-by="id"
                  placeholder="Pesquise o Produto"
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading_product"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="aux_product_debounce"
                  v-validate="'required'"
                >
                  <span slot="noOptions">Pesquise pelo produto</span>

                  <span slot="noResult"
                    >Oops! Nenhum produto encontrado. Pesquise outro termo</span
                  >
                </multiselect>
                <b-form-invalid-feedback :state="!errors.has('products_cupom')">
                  Por favor, selecione o produto
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col cols="12" md="6">
              <b-row>
                <b-col>
                  <b-form-group id="limit" label="" label-for="limit">
                    <b-form-checkbox
                      name="limit"
                      v-model="has_limit"
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox">
                        Limite de uso <span class="opcional">(opcional)</span>

                        <span class="descricao-checkbox"
                          >Poderão usar este cupom algumas vezes
                        </span>
                      </p>
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- <b-col cols="12" md="6"> -->
                  <b-form-group
                    label="Quantidade"
                    label-for="qtd"
                    data-anima="top"
                    v-if="has_limit"
                  >
                    <b-form-input
                      id="qtd"
                      name="qtd"
                      v-model="cupom.limit"
                      min="1"
                      type="number"
                      v-validate="'required|numeric|min_value:1'"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('qtd')">
                      Por favor, insira a quantidade (min 1).
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" md="6">
              <b-row>
                <b-col>
                  <b-form-group id="validate" label="" label-for="validate">
                    <b-form-checkbox
                      name="validate"
                      v-model="has_validate"
                      size="lg"
                      @change="changeHasValidate"
                      switch
                    >
                      <p class="info-checkbox">
                        Validade <span class="opcional">(opcional)</span>

                        <span class="descricao-checkbox"
                          >Programar uma data limite para o Cupom
                        </span>
                      </p>
                    </b-form-checkbox>
                  </b-form-group>

                  <b-form-group
                    id="validate"
                    label=""
                    label-for="validate"
                    v-if="has_validate"
                  >
                    <b-form-group
                      label="Data vencimento"
                      label-for="qtd"
                      data-anima="top"
                    >
                      <b-form-input
                        id="date"
                        name="date"
                        v-model="cupom.due_date"
                        type="datetime-local"
                        :min="minTime"
                        v-validate="'required'"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!errors.has('date')">
                        Por favor, insira a data de vencimento.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        variant="secondary_outline"
        :disabled="loading"
        @click="cancel"
        class="mr-4"
      >
        Cancelar
      </BaseButton>
      <BaseButton variant="link-info" :disabled="loading" @click="onSubmit">
        {{ cupom_edit.name ? "Atualizar cupom" : "Criar cupom agora" }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";
import { Money } from "v-money";
import moment from "moment-timezone";

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import CouponService from "@/services/resources/CouponService";
const serviceCoupon = CouponService.build();

export default {
  data() {
    return {
      submit: false,
      loading: false,
      loading_product: false,
      products: [],
      search: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      has_limit: false,
      has_validate: false,
      minTime: null,
      cupom: {
        name: "",
        type: "PERCENTAGE",
        amount: 0,
        only_products: false,
        products: [],
        limit: null,
        due_date: null,
      },
      products_selects: [],
      types: [
        { value: "PERCENTAGE", text: "Porcentagem" },
        { value: "AMOUNT", text: "Valor" },
      ],
    };
  },
  props: {
    product_read: {
      type: Boolean,
      default: () => false,
    },
    cupom_edit: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Multiselect,
    Money,
  },

  methods: {
    changeHasValidate(e) {
      if (!this.cupom_edit.name) {
        this.cupom.due_date = e
          ? moment()
              .add(5, "minutes")
              .format()
          : null;

        this.minTime = e
          ? moment()
              .add(5, "minutes")
              .format()
          : this.minTime;
      }
    },
    openModal() {
      if (this.cupom_edit.name) {
        this.cupom = Object.assign({}, this.cupom_edit);

        this.cupom.only_products = this.cupom.only_products ? true : false;
        if (this.cupom.limit) {
          this.has_limit = true;
        }
        if (this.cupom.due_date) {
          this.cupom.due_date = moment(this.cupom.due_date).format(
            "YYYY-MM-DD[T]HH:mm"
          );
          this.has_validate = true;
        } else {
          this.cupom.due_date = moment()
            .add(5, "minutes")
            .format("YYYY-MM-DD[T]HH:mm");

          this.minTime = this.cupom.due_date;
        }
        this.cupom.products = this.cupom.products.map((item) => {
          return {
            id: item.product.id,
            name: item.product.name,
          };
        });
      } else {
        this.cupom.due_date = moment()
          .add(5, "minutes")
          .format();
        this.minTime = this.cupom.due_date;
      }
    },
    reset() {
      this.has_limit = false;
      this.has_validate = false;
      this.minTime = null;
      this.cupom = {
        name: "",
        type: "PERCENTAGE",
        amount: 0,
        only_products: false,
        products: [],
        limit: null,
        due_date: null,
      };

      this.products_selects = [];

      if (this.cupom_edit.name) {
        this.$emit("resetEdit");
      }
    },
    // produto
    aux_product_debounce(query) {
      this.loading_product = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function() {
      this.fetchProducts(this.search);
    }, 500),

    fetchProducts(search = null) {
      let data = {
        list: true,
      };

      if (search) data.search = search;

      this.products = [];

      serviceProduct
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            this.products.push({ id: element.id, name: element.name });
          });
        })
        .catch((err) => {
          console.log(err);
          this.products = [];
        })
        .finally(() => {
          this.loading_product = false;
        });
    },
    formatData() {
      return new Promise((resolve) => {
        this.cupom.name = this.cupom.name.toUpperCase();
        this.cupom.amount = +this.cupom.amount;
        this.cupom.limit =
          this.cupom.limit && this.has_limit ? +this.cupom.limit : null;
        if (this.has_validate) {
          if (new Date(this.cupom.due_date).getTime() < moment.now()) {
            this.$bvToast.toast(
              `Data de validade deve ser maior do que ${moment()
                .add(5, "minutes")
                .format("DD/MM/YYYY HH:mm")}`,
              {
                title: "Cupom",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            resolve(false);
          }
          this.cupom.due_date = moment
            .tz(this.cupom.due_date, "America/Sao_Paulo")
            .utc()
            .format();
        } else {
          this.cupom.due_date = null;
        }

        if (this.cupom.only_products && this.cupom.products.length) {
          this.products_selects = [...this.cupom.products];
          this.cupom.products = this.cupom.products.map((item) => item.id);
        } else {
          this.cupom.products = [];
        }
        resolve(true);
      });
    },
    onSubmit() {
      this.submit = true;

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.formatData().then((format) => {
            if (format) {
              this.loading = true;

              if (!this.cupom_edit.name) {
                this.createCupom();
              } else {
                this.updateCupom();
              }
            }
          });
        }
      });
    },
    createCupom() {
      serviceCoupon
        .create(this.cupom)
        .then(() => {
          this.$bvToast.toast("Cupom criado com sucesso", {
            title: "Cupom",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });

          this.$emit("close");
          this.$bvModal.hide("coupon-new");
        })
        .catch((err) => {
          console.log(err);
          if (this.products_selects.length) {
            this.cupom.products = this.products_selects;
          }
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    updateCupom() {
      serviceCoupon
        .update(this.cupom)
        .then(() => {
          this.$bvToast.toast("Cupom atualizado com sucesso", {
            title: "Cupom",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });

          this.$emit("close");
          this.$bvModal.hide("coupon-new");
        })
        .catch((err) => {
          console.log(err);
          if (this.products_selects.length) {
            this.cupom.products = this.products_selects;
          }
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.produto-nome {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #0d0d0f;
}

.label-title {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}

.title-checkbox {
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.2px;
  color: #333333;
  font-weight: normal;
}
.info-checkbox {
  margin-top: 0;
  cursor: pointer;
  font-weight: 600;
}
.info-checkbox span {
  margin-left: 0;
  font-weight: normal;
}
.v-money {
  width: 100%;
}
</style>
