<template>
  <BaseModal
    name="prelink-cupom"
    size="md"
    title="Vamos iniciar a geração do link"
    @hidden="reset"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-prelink" v-else>
      <p>
        Preencha os campos abaixo para realizar uma pré configuração do seu
        link. Após o preenchimento clique em 'Continuar' que iremos redirecionar
        para concluir a geração.
      </p>
      <b-form novalidate class="mt-3">
        <b-form-group label="Selecione o produto" label-for="product_id">
          <multiselect
            id="ajax"
            label="name"
            track-by="id"
            v-model="data.produto"
            placeholder="Pesquise o Produto"
            selectLabel="Clique para selecionar"
            deselectLabel="Clique para remover"
            selectedLabel="✔️"
            open-direction="bottom"
            :options="products"
            :multiple="false"
            :searchable="true"
            :loading="loading_product"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :options-limit="10"
            :show-no-results="true"
            :hide-selected="true"
            @search-change="aux_product_debounce"
          >
            <span slot="noOptions">Pesquise pelo produto</span>

            <span slot="noResult"
              >Oops! Nenhum produto encontrado. Pesquise outro termo</span
            >
          </multiselect>
          <b-form-invalid-feedback :state="!errors.has('product_id')">
            Por favor, selecione o produto
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- cupom -->
        <b-form-group
          label="Cupons disponíveis para o produto"
          label-for="cupom_id"
          v-if="data.produto"
        >
          <multiselect
            data-anima="top"
            v-model="data.cupom"
            placeholder="Selecione o cupom"
            label="name"
            track-by="id"
            :options="cupons"
            :multiple="false"
            :taggable="false"
          >
            <span slot="noOptions">Pesquise o Cupom</span>

            <span slot="noResult">Oops! Nenhum cupom encontrado.</span>
          </multiselect>
          <b-form-invalid-feedback :state="!errors.has('cupom_id')">
            Por favor, selecione o cupom
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </section>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        variant="secondary_outline"
        :disabled="loading"
        @click="cancel"
        class="mr-4"
      >
        Cancelar
      </BaseButton>

      <div id="redirect-link">
        <BaseButton
          variant="link-info"
          :disabled="loading || !data.produto || !data.cupom"
          @click="redirectLink"
        >
          Continuar
        </BaseButton>
      </div>

      <b-tooltip
        v-if="!data.produto || !data.cupom"
        target="redirect-link"
        title="Preencha os campos acima"
      />
    </template>
  </BaseModal>
</template>

<script>
import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import CouponService from "@/services/resources/CouponService";
const serviceCoupon = CouponService.build();

import Multiselect from "vue-multiselect";
import _ from "lodash";
export default {
  props: ["productRead"],
  name: "PreLink",
  data() {
    return {
      loading: false,
      loading_product: false,
      products: [],
      cupons: [],
      data: {
        produto: null,
        cupom: null,
      },
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    "data.produto"(value) {
      this.getCupom(value.id);
    },
  },
  methods: {
    reset() {
      this.products = [];
      this.cupons = [];
      this.data = {
        produto: null,
        cupom: null,
      };
    },
    redirectLink() {
      const produto = this.data.produto;
      const cupom = this.data.cupom;
      // this.$router.push(
      //   `/cupom/?c_id=${cupom.id}&c_name=${cupom.name}&p_id=${produto.id}&p_name=${produto.name}&p_type=${produto.type}`
      // );
      this.$bvModal.hide("prelink-cupom");
      this.$emit("emitLink", { cupom: cupom, produto: produto });
    },
    // produto
    aux_product_debounce(query) {
      this.loading_product = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function() {
      this.fetchProducts(this.search);
    }, 500),

    fetchProducts(search = null) {
      let data = {
        list: true,
      };

      if (search) data.search = search;

      this.products = [];

      serviceProduct
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            this.products.push({
              id: element.id,
              name: element.name,
              type: element.type,
            });
          });
        })
        .catch((err) => {
          console.log(err);
          this.products = [];
        })
        .finally(() => {
          this.loading_product = false;
        });
    },
    getCupom(product_id) {
      this.loading = true;

      let data = {
        id: `product/${product_id}`,
      };

      this.cupons = [];

      serviceCoupon
        .read(data)
        .then((response) => {
          console.log(response);
          response.forEach((el) => {
            this.cupons.push({
              id: el.id,
              name: el.name,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.container-prelink p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
</style>
